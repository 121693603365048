import { _ } from "$json/lib/gettext.js";
import { isOptional } from "$json/lib/control-helpers";
/* optional:
 *
 * Set placeholder to '(Optional)' on non-required fields
 *
 * Author: Niels Giesen
 * Copyright 2015 Berkeley Bridge
 *
 * @todo: support textareas and grid fields
 */
(function ($, win, doc) {
  var translations = {
    "(Optional)": {
      nl: "(Optioneel)",
      de: "(Fakultativ)",
      fr: "(Facultatif)",
      ru: "(Необязательный)",
      pt: "(Opcional)",
      es: "(Opcional)"
    }
  };

  $(function () {
    _.addTranslations(translations);

    $(doc).on("bb:preHandleData", function (event, data) {
      if (data && data.groups) {
        $.each(data.groups, function (__, group) {
          if (group.current) {
            $.each(group.controls, function (__, control) {
              if (
                !control.placeholder &&
                (control.controltype === "edit" ||
                  control.controltype === "memo") &&
                isOptional(control)
              ) {
                control.placeholder = _("(Optional)");
              }
            });
          }
        });
      }
    });
  });
})(jQuery, window, document);
