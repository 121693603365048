import { bb } from "$json";
import { canEditEarlier } from "$json/lib/feature-queries";
import { conf } from "$json/lib/conf";
/* has-required:
 *
 * Note whether there is any required question currently
 *
 * Author: Niels Giesen
 * Copyright 2016 Berkeley Bridge
 *
 */
(function ($, win, doc) {
  $(function () {
    $(doc).on("bb:preHandleData", function (event, data) {
      if (data && data.groups) {
        var req = data.groups.some(function (group) {
          return (
            (canEditEarlier(conf) || group.current) &&
            group.controls.some(function (control) {
              return control.notnull;
            })
          );
        });
        bb.Mode.toggle("hasRequired", req);
      }
    });
  });
})(jQuery, window, document);
