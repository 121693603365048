/* selected-model:
 *
 * Set data.modeldescription to data.models[{selected:true}].modelname
 *
 * Author: Niels Giesen
 * Copyright 2015, 2017 Berkeley Bridge
 *
 */
(function ($, win, doc) {
  $(function () {
    $(doc).on("bb:preHandleData", function (event, data) {
      if (data && data.models) {
        var selected = $.grep(data.models, function (model) {
          return model.selected;
        });
        if (selected[0]) data.modeldescription = selected[0].modelname;
      }
    });
  });
})(jQuery, window, document);
